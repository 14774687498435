import React, { Fragment, useContext, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Footer from './Footer/index';
import Loader from './Loader';
import TapTop from './TapTop/index';
import Header from './Header';
import SideBarLayout from './SideBar-Layout';
import Themecustomizer from './ThemeCustomizer';
import CheckContext from '../_helper/customizer';
import ConfigDB from '../Config/Theme-Config';
import AnimationThemeContext from '../_helper/AnimationTheme';
import { ToastContainer } from 'react-toastify';

const Layout = ({ children, classNames, ...rest }) => {
  const {
    sidebar_types,
    settings,
    toggleIcon,
    setToggleIcon,
    defaultClass,
    setDefaultClass,
  } = useContext(CheckContext);
  const settings1 = localStorage.getItem('sidebar_Settings') || settings;
  const sidebar_types1 = localStorage.getItem('sidebar_types') || sidebar_types;
  const location = useLocation();
  const { animation } = useContext(AnimationThemeContext);
  const animationTheme =
    localStorage.getItem('animation') ||
    animation ||
    ConfigDB.data.router_animation;
  const nodeRef = useRef(null);
  window.addEventListener('resize', () => {
    if (window.innerWidth - 440 <= 575) {
      setToggleIcon(true);
    } else {
      setToggleIcon(false);
    }
    if (window.innerWidth <= 992) {
      setDefaultClass(true);
    } else setDefaultClass(false);
  });

  const error = console.error;
  console.error = (...args) => {
    if (/defaultProps/.test(args[0])) return;
    error(...args);
  };
  return (
    <Fragment>
      <Loader />
      <TapTop />
      <div
        className={`page-wrapper ${
          !defaultClass ? sidebar_types1 : 'compact-wrapper'
        } ${settings1}`}
        id="pageWrapper"
      >
        <div className={`page-header close_icon `}>
          <Header />
        </div>
        <div className="page-body-wrapper">
          <div className="sidebar-wrapper  close_icon">
            <div>
              <SideBarLayout />
            </div>
          </div>
          <div className="page-body" ref={nodeRef}>
            <TransitionGroup {...rest}>
              <CSSTransition
                key={location.key}
                timeout={100}
                classNames={animationTheme}
                nodeRef={nodeRef}
                unmountOnExit
              >
                <div>
                  <Outlet />
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
          <Footer />
        </div>
      </div>
      {/* <Themecustomizer /> */}
      <ToastContainer />
    </Fragment>
  );
};
export default Layout;
