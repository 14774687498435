import React, { Fragment } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import CustomerTable from "./customerTable";

const CustomerComponent = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col xl="12" md="12" className="xl ">
            <CustomerTable />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default CustomerComponent;
