import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Context from './index';
import { base_url_CRM } from '../../apiService';
import { login, password, tenantId, userId } from '../authVar';

const CustomerProvider = (props) => {
  const [customersList, setCustomersList] = useState([]);
  const [data, setData] = useState([]);

  const getCustomers = async () => {
    try {
      const apiUrl = `${base_url_CRM}/customer/all`;
      const response = await axios.get(apiUrl, {
        auth: {
          username: userId,
          password: password,
        },
      });
      setCustomersList(response.data);
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    console.log('login', login);
    if (login) {
      getCustomers();
    }
  }, [setCustomersList, setData]);

  const createCustomer = async (data1, imgUrl) => {
    debugger;
    const customerDataSave = {
      dealerName: data1.dealerName,
      dealerType: Number(data1.dealerType),
      email: data1.email,
      location: data1?.location,
      isActive: Number(data1.isActive),
      taxId: data1.taxId,
      phoneNumber: data1?.phoneNumber,
    };
    try {
      const apiUrl = `${base_url_CRM}/customer/add?tenantId=6`;
      const response = await axios.post(apiUrl, customerDataSave, {
        auth: {
          username: userId,
          password: password,
        },
      });
    } catch (error) {
      console.log('error', error);
    }

    getCustomers();
  };

  const editCustomer = async (data, imgUrl) => {
    debugger;
    const dealerDataEdit = {
      dealerId: data?.dealerId,
      dealerName: data.dealerName,
      email: data.email,
      location: data?.location,
      isActive: Number(data.isActive),
      taxId: data.taxId,
      phoneNumber: data?.phoneNumber,
    };
    try {
      const apiUrl = `${base_url_CRM}/customer/update?tenantId=${data?.tenantId}`;
      const response = await axios.put(apiUrl, dealerDataEdit, {
        auth: {
          username: userId,
          password: password,
        },
      });
    } catch (error) {
      console.log('error', error);
    }
    getCustomers();
  };

  const deletedCustomer = async (id) => {
    debugger;
    const apiUrl = `${base_url_CRM}/dealer/delete?id=${id}`;
    try {
      const response = await axios.delete(apiUrl, {
        auth: {
          username: userId,
          password: password,
        },
      });
    } catch (error) {
      console.log('error', error);
    }
    getCustomers();
  };

  return (
    <Context.Provider
      value={{
        ...props,
        customersList,
        data,
        createCustomer: createCustomer,
        editCustomer: editCustomer,
        deletedCustomer: deletedCustomer,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default CustomerProvider;
