import React, { Fragment } from 'react';
import './App.css';
import Routers from './Routes';
import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/customizer/CustomizerProvider';
import DealerProvider from './_helper/Dealer/DealerProvider';
import CustomerProvider from './_helper/Customer/CustomerProvider';
import LeadProvider from './_helper/Leads/LeadProvider';
import AuthProvider from './_helper/Auth/AuthProvider';
function App() {
  return (
    <Fragment>
      <AuthProvider>
        <CustomizerProvider>
          <DealerProvider>
            <CustomerProvider>
              <LeadProvider>
                <AnimationThemeProvider>
                  <Routers />
                </AnimationThemeProvider>
              </LeadProvider>
            </CustomerProvider>
          </DealerProvider>
        </CustomizerProvider>
      </AuthProvider>
    </Fragment>
  );
}
export default App;
