import { UsersComponentSvg } from '../../Data/svgIcons';

export const MENUITEMS = [
  {
    menutitle: 'Dealer',
    Items: [
      {
        path: `/dealer`,
        bookmark: true,
        icon: UsersComponentSvg,
        title: 'Dealer',
        type: 'link',
      },
    ],
  },
  {
    menutitle: 'Tenants',
    Items: [
      {
        path: `/dashboard/tenant`,
        bookmark: true,
        icon: UsersComponentSvg,
        title: 'Tenants',
        type: 'link',
      },
    ],
  },
  {
    menutitle: 'Customers',
    Items: [
      {
        path: `/dashboard/customers`,
        bookmark: true,
        icon: UsersComponentSvg,
        title: 'Customers',
        type: 'link',
      },
    ],
  },
];
