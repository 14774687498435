import React, { Fragment } from 'react';
import { Breadcrumbs, H5 } from '../../AbstractElements';
import CreateLeadCompopnent from './Create Lead';

const CreateLead = () => {
  return (
    <Fragment>
      <Breadcrumbs home="/leads" parent="Lead" title="Add New Lead" />
      <CreateLeadCompopnent />
    </Fragment>
  );
};

export default CreateLead;
