import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import LeadsComponent from '../../Component/Leads';

const Leads = () => {
  return (
    <Fragment>
      {/* <Breadcrumbs parent="Leads" title=" Leads" /> */}
      <LeadsComponent />
    </Fragment>
  );
};
export default Leads;
