import App from "./App";
import reportWebVitals from "./reportWebVitals";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "@fortawesome/free-solid-svg-icons";
import "@fortawesome/react-fontawesome";
import "./i18n";
import { unstable_batchedUpdates } from "react-dom";

unstable_batchedUpdates(() => {
  console.error = () => {};
});

class ErrorBoundary extends React.Component {
  componentDidCatch(error, info) {
    if (error.message.includes("ToastContainer")) {
      // Suppress the warning related to ToastContainer
      return;
    }
    // Log or handle other errors
  }
  // componentDidMount = () => {
  //   if (!sessionStorage.getItem("pageLoaded")) {
  //     console.log("pageLoaded");
  //     console.log(
  //       'localStorage.getItem("login")',
  //       localStorage.getItem("login")
  //     );
  //     // Page was loaded for the first time in this session
  //     sessionStorage.setItem("pageLoaded", "true");
  //     // Clear local storage
  //     localStorage.clear();
  //   }

  //   // Add event listener for tab close
  //   window.addEventListener("beforeunload", this.handleTabClose);
  // };
  // componentWillUnmount = () => {
  //   console.log("beforeunload");
  //   // Remove event listener when component is unmounted
  //   window.removeEventListener("beforeunload", this.handleTabClose);
  // };
  // handleTabClose = () => {
  //   console.log("beforeunload");
  //   localStorage.removeItem("login");
  //   sessionStorage.removeItem("pageLoaded");
  // };
  render() {
    return this.props.children;
  }
}

const rootElement = document.getElementById("root");

const root = ReactDOM.createRoot(rootElement);

root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
