import { useContext, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import {
  CreateAccount,
  EmailAddress,
  ForgotPassword,
  Login,
  Password,
  RememberPassword,
  SignIn,
} from '../Constant';
import { toast } from 'react-toastify';
import { Mail, Lock } from 'react-feather';
import imgg from '../assets/images/logo/logo.png';
import MediaIcons from '../CommonElements/MediaIcons';
import { base_url } from '../apiService';
import axios from 'axios';
import AuthAppContext from '../_helper/Auth';

export default function SignInForm() {
  const history = useNavigate();
  const [validateEmail, setValidateEmail] = useState(false);
  const [validatePassword, setValidatePassword] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isPasswordVisible, setisPasswordVisible] = useState(false);
  const { userlogin } = useContext(AuthAppContext);
  const toggle = () => {
    setisPasswordVisible(!isPasswordVisible);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    // email === "test123@gmail.com" && password === "Test@123"
    if (!email) {
      setValidateEmail(true);
    } else if (!password) {
      setValidatePassword(true);
    } else if (email === 'prasad' && password === 'prasad') {
      var logindata = {
        email_id: email,
        pwd: password,
      };
      // userlogin(logindata);
      localStorage.setItem('userId', email);
      localStorage.setItem('password', password);
      localStorage.setItem('login', JSON.stringify(true));
      toast.success('Login Success...!');
      // history(`${process.env.PUBLIC_URL}/dashboard/default`);
      window.location.href = `/leads`;
    } else {
      toast.error('Please Enter valid email or password...!');
    }
  };

  return (
    <>
      <div
        className="header-logo-wrapper-login col-auto p-1"
        id="out_side_click"
      >
        <div className="toggle-sidebar" id="sidebar-toggle">
          <img src={imgg} />
        </div>
      </div>

      <div className="login-card1">
        <Form className="theme-form login-form">
          <div className="login-header text-center">
            <h4>{Login}</h4>
            <h6>
              If you don’t have access please contact your reporting manager
            </h6>
          </div>

          <FormGroup>
            <Label>{EmailAddress}</Label>
            <InputGroup>
              <InputGroupText>
                <Mail />
              </InputGroupText>
              <Input
                type="email"
                required
                placeholder="email@gmail.com"
                defaultValue={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </InputGroup>
          </FormGroup>

          <FormGroup>
            <Label>{Password}</Label>
            <InputGroup>
              <InputGroupText>
                <Lock />
              </InputGroupText>
              <Input
                type={isPasswordVisible ? 'text' : 'password'}
                name="login[password]"
                required
                defaultValue={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <div className="show-hide" onClick={toggle}>
                <span className={!isPasswordVisible ? 'show' : ''}></span>
              </div>
            </InputGroup>
          </FormGroup>

          <FormGroup className="login-btn forgot-password-container">
            <div className="checkbox">
              <Input id="checkbox1" type="checkbox" />
              <Label className="text-muted" for="checkbox1">
                {RememberPassword}
              </Label>
            </div>
            <Link href="/pages/authentication/forgetpwd" className="link">
              {ForgotPassword}?
            </Link>
          </FormGroup>
          <FormGroup>
            <Button
              className="btn-block"
              color="primary"
              type="submit"
              onClick={handleLogin}
            >
              {SignIn}
            </Button>
          </FormGroup>
          <p>
            Don't have account?
            <Link href="/pages/authentication/create-pwd" className="ms-2">
              {CreateAccount}
            </Link>
          </p>
        </Form>
      </div>
    </>
  );
}
