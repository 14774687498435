import React, { Fragment, useContext } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
// import ProjectAppContext from '../../../_helper/project-app';
import { Btn } from '../../../AbstractElements';
import {
  Add,
  Cancel,
  ClientName,
  EndingDate,
  EnterSomeDetails,
  NOTES,
  Priority,
  ProjectRate,
  ProjectSize,
  ProjectTitle,
  ProjectType,
  StartingDate,
} from '../../../Constant';
import MainTitle from './MainTitle';
import MainType from './MainType';
import DropItem from './DropItem';
import MainDates from './MainDates';
import MainPriority from './MainPriority';
import {
  carRepair,
  city,
  emailAddress,
  inspection,
  leadname,
  mobileNumber,
  serviceType,
  warranty,
} from '../../../Constant/crmLabelConstants';

const MainList = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  //   const { addNewProject } = useContext(ProjectAppContext);
  const history = useNavigate();
  // const AddProject = (data) => {
  //   console.log('data', data);
  // };
  const AddProject = (data) => {
    console.log('data', data);
    history(`/leads`);
  };
  return (
    // onSubmit={handleSubmit(AddProject)}
    <Form className="theme-form" onSubmit={() => handleSubmit(AddProject)}>
      <Row className="mb-3">
        <Col sm="3">
          <FormGroup className="mb-3">
            <Label>{leadname}</Label>
            <Input
              className="form-control"
              type="text"
              name="name"
              placeholder="Lead name *"
              {...register('name', { required: true })}
            />
            <span style={{ color: 'red' }}>
              {errors.title && 'Name is required'}{' '}
            </span>
          </FormGroup>
        </Col>
        <Col sm="3">
          <Label>{emailAddress}</Label>
          <input
            className="form-control"
            type="text"
            name="email"
            placeholder="Email Address *"
            {...register('email', { required: true })}
          />
          <span style={{ color: 'red' }}>
            {errors.title && 'Email address is required'}{' '}
          </span>
        </Col>
        <Col sm="3">
          <Label>{mobileNumber}</Label>
          <input
            className="form-control"
            type="text"
            name="mobileNo"
            placeholder="Mobile Number *"
            {...register('mobileNo', { required: true })}
          />
          <span style={{ color: 'red' }}>
            {errors.title && 'Mobile No is required'}{' '}
          </span>
        </Col>
        <Col sm="3">
          <Label>{serviceType}</Label>
          <Input
            type="select"
            name="service"
            placeholder="Select Service"
            className="form-control digits"
          >
            <option value="" disabled selected>
              Select Service
            </option>
            <option value="Inspection">{inspection}</option>
            <option value="Warranty">{warranty}</option>
            <option value="CarRepair">{carRepair}</option>
          </Input>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm="3">
          <Label>{city}</Label>
          <Input
            type="select"
            name="city"
            placeholder="Select City"
            className="form-control digits"
          >
            <option value="" disabled selected>
              Select City
            </option>
            <option value="Delhi">Delhi</option>
            <option value="Mumbai">Mumbai</option>
            <option value="Bangalore">Bangalore</option>
            <option value="Hyderabad">Hyderabad</option>
            <option value="Chennai">Chennai</option>
            <option value="Kolkata">Kolkata</option>
            <option value="Pune">Pune</option>
            <option value="Ahmedabad">Ahmedabad</option>
            <option value="Jaipur">Jaipur</option>
            <option value="Surat">Surat</option>
            <option value="Lucknow">Lucknow</option>
            <option value="Kanpur">Kanpur</option>
            <option value="Nagpur">Nagpur</option>
            <option value="Indore">Indore</option>
            <option value="Vadodara">Vadodara</option>
            <option value="Bhopal">Bhopal</option>
            <option value="Coimbatore">Coimbatore</option>
            <option value="Patna">Patna</option>
            <option value="Agra">Agra</option>
            <option value="Visakhapatnam">Visakhapatnam</option>
            <option value="Chandigarh">Chandigarh</option>
          </Input>
        </Col>
        {/* <Col sm="3">
          <MainDates StartingDate={StartingDate} />
        </Col>
        <Col sm="3">
          <MainDates EndingDate={EndingDate} />
        </Col> */}
      </Row>
      <Row>
        <Col>
          <div className="mb-3">
            <Label>{NOTES}</Label>
            <input
              type="textarea"
              className="form-control"
              name="description"
              rows="3"
              style={{ height: '80px' }}
              {...register('description', { required: true })}
            />
            <span style={{ color: 'red' }}>
              {errors.description && 'Some Details is required'}
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="text-end">
          <div className="mb-0">
            <Btn attrBtn={{ color: 'secondary', className: 'me-3' }}>{Add}</Btn>
            <Btn attrBtn={{ color: 'danger' }}>{Cancel}</Btn>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
export default MainList;
