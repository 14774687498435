import React, { Fragment } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import DealerComponent from "../../Component/Dealer";

const Dealer = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Dealer" title=" Dealer" />
      <DealerComponent />
    </Fragment>
  );
};
export default Dealer;
