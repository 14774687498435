import React, { Fragment } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import DealerTable from "./dealerTable";

const DealerComponent = () => {
 
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col xl="12" md="12" className="xl ">
            <DealerTable />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default DealerComponent;
