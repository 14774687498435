import axios from 'axios';
//  export const  base_url = 'http://164.52.217.96:30029/'
// export const base_url = "https://wdcrmapis.wisedrive.in/"; //prod
export const base_url = 'https://wisedriveportal.wisedrive.in/'; //test
//  export const base_url = "https://wdcrmapis.wisedrive.in/";
// export const base_url_CRM = "https://wisedrive.co.in/apiv1";
// export const base_url_CRM = 'https://216.48.190.203:8443/apiv1';
export const base_url_CRM = 'http://216.48.190.203:8080/apiv1';
export const base_url_AUTH = 'http://216.48.190.203:8080';

/*login Api start*/
// export function loginApi(email, pwd) {
//   return axios.get(`${base_url}login/employee?email_id=${email}&pwd=${pwd}`);
// }
export function loginApi(email, pwd) {
  return axios.get(
    `https://wisedrive.co.in/login?email_id=${email}&pwd=${pwd}`
  );
}
