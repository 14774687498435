import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../AbstractElements';
import { TenantComponent } from '../../Component/Tenant';

const Tenants = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Tenants" title=" Tenants" />
      <TenantComponent />
    </Fragment>
  );
};
export default Tenants;
