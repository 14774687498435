import {
  Dealer,
  AddNew,
  AddDealer,
  Name,
  LastName,
  DealerType,
} from '../../Constant';
import { Btn, H5 } from '../../AbstractElements';
import defaultuser from '../../assets/images/user/user.png';
import {
  Col,
  Card,
  CardHeader,
  Table,
  Media,
  Button,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  FormGroup,
  Form,
} from 'reactstrap';

import React, { Fragment, useContext, useEffect, useState } from 'react';
import { PlusSquare } from 'react-feather';
import { useForm } from 'react-hook-form';
import DealerAppContext from '../../_helper/Dealer';
import { toast } from 'react-toastify';

const style2 = {
  width: 60,
  fontSize: 13,
  padding: 3,
};

const tableHeaders = [
  'Id',
  'Dealer Name',
  'Email',
  'Location',
  'IsActive',
  'TaxId',
  'Last Modified Date',
  'Action',
];

const sampleObj = {
  dealerId: 2,
  tenant_id: 6,
  dealerName: 'VamsiCar',
  dealerType: 2,
  email: 'test@email.com',
  location: 'kakinada',
  isActive: false,
  taxId: '123',
  creationDate: '2024-07-17T16:32:06.000+00:00',
  lastModified: '2024-07-17T16:32:09.000+00:00',
};

const DealerTable = () => {
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const toggle = () => setModal(!modal);
  const { createDealer, deletedDealer, dealers, editDealer } =
    useContext(DealerAppContext);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();
  const handleEdit = (item) => {
    console.log('item?.id', item);
    console.log('item?.id', item);
    setValue('tenantId', item?.tenant_id);
    setValue('dealerId', item?.dealerId);
    setValue('isActive', item?.isActive);
    setValue('dealerName', item?.dealerName);
    setValue('location', item?.location);
    setValue('email', item?.email);
    setValue('phoneNumber', item?.phoneNumber);
    setValue('taxId', item?.taxId);
    setIsEdit(true);
    setModal(true);
  };
  const handleDelete = (item) => {
    if (item) {
      deletedDealer(item?.dealerId);
    }
  };
  console.log('data', dealers);

  const AddDealer = (data) => {
    if (data !== undefined) {
      createDealer(data, defaultuser);
      setModal(false);
    } else {
      errors.showMessages();
    }
    reset();
  };
  const EditDealer = (data) => {
    if (data !== undefined) {
      const status = editDealer(data, defaultuser);
      console.log('status', status);
      setModal(false);
      setIsEdit(false);
    } else {
      errors.showMessages();
    }
    reset();
  };

  return (
    <Fragment>
      {' '}
      <Col sm="12">
        <Card>
          <CardHeader>
            <Media>
              <H5>Dealer</H5>
              <Media body className="text-end">
                <span className="pull-right">
                  <Button
                    className="btn-block "
                    color="primary"
                    type="submit"
                    onClick={toggle}
                  >
                    <span className="d-flex justify-content-between align-items-center">
                      <PlusSquare size={15} className="m-1" /> {AddNew}
                    </span>
                  </Button>
                </span>
              </Media>
            </Media>
          </CardHeader>
          <div className="table-responsive">
            <Table>
              <thead>
                <tr>
                  {tableHeaders?.map((head) => (
                    <th scope="col">{head}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dealers?.map((item) => (
                  <tr key={item.id}>
                    <th scope="row">{item?.dealerId}</th>
                    <td>{item?.dealerName}</td>
                    <td>{item?.email}</td>
                    <td>{item?.location}</td>
                    <td>{item?.isActive ? 'True' : 'False'}</td>
                    <td>{item?.taxId}</td>
                    <td>{item?.lastModified}</td>
                    <td>
                      <div>
                        <span>
                          <Button
                            style={style2}
                            className="btn btn-xs"
                            color="danger"
                            type="button"
                            onClick={(e) => handleDelete(item)}
                          >
                            Delete
                          </Button>
                        </span>
                        <span>
                          <Button
                            style={style2}
                            className="btn btn-xs ms-2"
                            color="primary"
                            type="button"
                            onClick={(e) => handleEdit(item)}
                          >
                            Edit{' '}
                          </Button>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader>
          {AddDealer}
          <Btn
            attrBtn={{
              color: 'transprant',
              className: 'btn-close',
              onClick: toggle,
              type: 'button',
            }}
          ></Btn>
        </ModalHeader>
        <ModalBody>
          <Form
            className="form-bookmark needs-validation"
            // onSubmit={handleSubmit(AddContact)}
            onSubmit={
              isEdit ? handleSubmit(EditDealer) : handleSubmit(AddDealer)
            }
          >
            <div className="form-row">
              <FormGroup className="col-md-12">
                <Row>
                  <Col sm="6">
                    <Label>{Name}</Label>
                    <input
                      className="form-control"
                      name="dealerName"
                      type="text"
                      {...register('dealerName', { required: true })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.dealerName && 'Dealer name is required'}
                    </span>
                  </Col>
                  <Col sm="6">
                    <Label>{'Location'}</Label>
                    <input
                      className="form-control"
                      name="location"
                      type="text"
                      {...register('location', { required: true })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.location && 'Please enter Location'}
                    </span>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup className="col-md-12">
                <Row>
                  <Col sm="6">
                    <Label>{'Email Address'}</Label>
                    <input
                      className="form-control"
                      name="email"
                      type="text"
                      {...register('email', { required: true })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.email && 'Please enter email.'}
                    </span>
                  </Col>
                  <Col sm="6">
                    <Label>{'Is Active'}</Label>
                    <Input
                      type="select"
                      className="form-control"
                      name="isActive"
                      {...register('isActive', {
                        required: false,
                      })}
                      onChange={(e) => setValue('isActive', e.target.value)}
                    >
                      <option value={1}>True</option>
                      <option value={0}>False</option>
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup className="col-md-12">
                <Row>
                  <Col sm="6">
                    <Label>{'Tax Id'}</Label>
                    <input
                      className="form-control"
                      name="taxId"
                      type="text"
                      {...register('taxId', { required: true })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.taxId && 'Please enter tax Id'}
                    </span>
                  </Col>
                  <Col sm="6">
                    <Label>{'Mobile'}</Label>

                    <input
                      className="form-control"
                      name="phoneNumber"
                      type="tel"
                      {...register('phoneNumber', {
                        required: 'Mobile number is required',
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message:
                            'Please enter a valid 10-digit mobile number',
                        },
                        minLength: {
                          value: 10,
                          message: 'Mobile number must be exactly 10 digits',
                        },
                        maxLength: {
                          value: 10,
                          message: 'Mobile number must be exactly 10 digits',
                        },
                      })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.phoneNumber && 'Please enter valid Mobile'}
                    </span>
                  </Col>
                </Row>
              </FormGroup>
            </div>
            <Btn
              attrBtn={{ color: 'secondary', className: 'me-1' }}
              type="submit"
            >
              {isEdit ? 'Update' : 'Save'}
            </Btn>
            &nbsp;
            <Btn attrBtn={{ color: 'primary', onClick: toggle }}>
              {'Cancel'}
            </Btn>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default DealerTable;
