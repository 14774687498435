import { Col, Container, Row } from "reactstrap";
import SignInForm from "./SignInForm";

export default function SignIn() {
  return (
    <Container fluid>
      <Row>
        <Col xl={12} className="p-0">    
          <SignInForm/>
        </Col>
      </Row>
    </Container>
)
}
