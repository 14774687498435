import React, { Fragment } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import CustomerComponent from "../../Component/Customer";

const Customer = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Customer" title=" Customer" />
      <CustomerComponent />
    </Fragment>
  );
};
export default Customer;
