import React, { Fragment, useState, useContext } from 'react';
import { Row } from 'reactstrap';
import { Image } from '../../AbstractElements';
import HeaderContain from './HeaderContain';
import SearchBar from './SearchBar';
import imgg from '../../assets/images/logo/logo.png';
import CheckContext from '../../_helper/customizer/index';
import { ToggleIconss } from '../../Data/svgIcons';

const Header = () => {
  const { toggleSidebar } = useContext(CheckContext);
  const [toggle, setToggle] = useState(false);

  const openCloseSidebar = () => {
    setToggle(!toggle);
    toggleSidebar(toggle);
  };
  return (
    <Fragment>
      <Row className="header-wrapper m-0">
        <div className="header-logo-wrapper col-auto p-0" id="out_side_click">
          <div className="logo-wrapper">
            <a href="#javascript">
              <Image
                attrImage={{ className: 'img-fluid', src: `${imgg}`, alt: '' }}
              />
            </a>
          </div>
          <div
            className="toggle-sidebar"
            onClick={() => openCloseSidebar()}
            id="sidebar-toggle"
          >
            {/* <i className="status_toggle sidebar-toggle d-flex">
              <ToggleIconss />
            </i> */}
            <img src={imgg} />
          </div>
        </div>
        {/* <SearchBar /> */}
        <HeaderContain />
      </Row>
    </Fragment>
  );
};
export default Header;
