import CreateLead from '../Pages/Leads/createLead';
import Customer from '../Pages/Customer';
import Dealer from '../Pages/Dealer';
import Leads from '../Pages/Leads';
import Tenants from '../Pages/Tenants';
import CreateLeadCompopnent from '../Pages/Leads/Create Lead';

export const routes = [
  {
    path: `/dealer`,
    Component: <Dealer />,
  },
  {
    path: `/customers`,
    Component: <Customer />,
  },
  {
    path: `/leads`,
    Component: <Leads />,
  },
  {
    path: `/tenants`,
    Component: <Tenants />,
  },
  {
    path: `/leads/CreateLead`,
    Component: <CreateLead />,
  },
  {
    path: `/test`,
    Component: <CreateLeadCompopnent />,
  },
];
