import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  Fragment,
} from 'react';
import { Col, Input } from 'reactstrap';
import { Search } from 'react-feather';
import { SearchIconss } from '../../Data/svgIcons';

const Searchbar = ({ searchValue, onSearch, onSearchClick, placeHolder }) => {
  const handleSearchKeyword = (e) => {
    onSearch(e); // Call the parent's search handler
  };
  return (
    <Fragment>
      <Col className="left-side-header ps-0 d-md-block dash-10 col-11">
        <div className="w-100 searchbar">
          <div className={`input-group form-group search-form`}>
            <span className="input-group-text">
              <Search onClick={onSearchClick} />
            </span>
            <Input
              type="text"
              placeholder={placeHolder}
              value={searchValue}
              defaultValue={searchValue}
              onChange={(e) => handleSearchKeyword(e.target.value)}
            />
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

export default Searchbar;
