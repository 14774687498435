import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import LeadsTable from './leadsTable';

const LeadsComponent = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col xl="12" md="12" className="xl ">
            <LeadsTable />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default LeadsComponent;
