import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Context from './index';
import { base_url_CRM } from '../../apiService';
import { login, password, userId } from '../authVar';
import {
  CityListApi,
  CouponCreateApi,
  LeadApi,
  LeadCreateApi,
  LeadsEnumApi,
  LeadUpdateApi,
  LeadFilterApi,
} from '../../api';
import { toast } from 'react-toastify';

const LeadProvider = (props) => {
  const [leads, setLeads] = useState([]);
  const [data, setData] = useState([]);
  const [dropDownList, setDropDownList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const getEnums = async () => {
    try {
      const response = await axios.get(LeadsEnumApi, {
        auth: {
          username: userId,
          password: password,
        },
      });
      setDropDownList(response.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getLeads = async (pageNo, noOfRows) => {
    try {
      const paginationData = { page: pageNo, size: noOfRows };
      const queryParams = new URLSearchParams(paginationData).toString();
      const LeadApiapiUrlWithParams = `${LeadApi}?${queryParams}`;
      const response = await axios.get(LeadApiapiUrlWithParams, {
        auth: {
          username: userId,
          password: password,
        },
      });
      console.log('response.data leads', response);
      setLeads(response.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getCityList = async () => {
    try {
      const response = await axios.get(CityListApi, {
        auth: {
          username: userId,
          password: password,
        },
      });
      setCityList(response.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    console.log('login', login);
    if (login) {
      getLeads(0, 20);
      getEnums();
      getCityList();
    }
  }, [setLeads, setData]);

  const getFilterLeads = async (searchtextValue, pageNo, noOfRows, sortCol) => {
    try {
      const searchData = {
        searchString: searchtextValue,
        page: pageNo,
        size: noOfRows,
        // sortColumnName: sortCol,
      };
      const queryParams = new URLSearchParams(searchData).toString();
      const LeadFilterApiapiUrlWithParams = `${LeadFilterApi}?${queryParams}`;
      const response = await axios.get(LeadFilterApiapiUrlWithParams, {
        auth: {
          username: userId,
          password: password,
        },
      });
      console.log('response.data leads search', response);
      setLeads(response.data);
    } catch (error) {
      console.log('error', error);
    }
  };
  const createLead = async (data1) => {
    const leadDataSave = {
      tenantId: 6,
      name: data1?.name,
      phoneNumber: data1?.mobileNumber,
    };
    const queryParams = new URLSearchParams(leadDataSave).toString();
    const apiUrlWithParams = `${LeadCreateApi}?${queryParams}`;
    try {
      const response = await axios.post(
        apiUrlWithParams,
        {},
        {
          auth: {
            username: userId,
            password: password,
          },
        }
      );
      if (response?.status === 200)
        toast.success('Dealer Created Successfully');
      else toast.error('Createing new Dealer failed');
    } catch (error) {
      console.log('error', error);
      toast.error('Createing new Dealer failed');
    }

    console.log('data1', data1, leadDataSave);
    getLeads(0, 10);
  };

  const editLead = async (data, itemData) => {
    debugger;
    itemData.city = { cityid: data?.city };
    itemData.name = data?.name;
    itemData.serviceTypes = data?.serviceTypes;
    itemData.mobileNumber = data?.mobileNumber;
    itemData.comments = data?.comments;
    itemData.contactStatus = 'ACTIVE';
    // itemData.couponCodeId = null;

    console.log('itemData,data', itemData, data);

    try {
      const apiUrl = `${LeadUpdateApi}${itemData?.leadsId}`;
      const response = await axios.put(apiUrl, itemData, {
        auth: {
          username: userId,
          password: password,
        },
      });
      console.log('response.data', response);
      if (response?.status === 200)
        toast.success('Dealer Updated Successfully');
      else toast.error('Dealer Update failed');
    } catch (error) {
      console.log('error', error);
      toast.error('Dealer Update failed');
    }
    console.log('dealerDataEdit', itemData);
    getLeads(0, 10);
  };

  const createCouponCode = async (couponData, itemData) => {
    console.log('itemData to coupon', itemData);
    debugger;

    // Convert the Date object to epoch time (in seconds)
    const epochTime = Math.floor(
      new Date(couponData?.endDate).getTime() / 1000
    );
    const createobj = {
      couponCode: couponData?.couponName,
      discountAmount: Number(couponData?.discountAmount),
      maxUsage: Number(couponData?.noOfTimesUsable),
      validTillEpoc: 0,
    };
    try {
      const response = await axios.post(CouponCreateApi, createobj, {
        auth: {
          username: userId,
          password: password,
        },
      });
      console.log('response.data', response);
      if (response?.status === 200) {
        editLeadCoupon(response?.data, itemData);
        // toast.success('Lead Coupon Successfully');
      } else toast.error('Coupon Create failed');
    } catch (error) {
      console.log('error', error);
      toast.error('Coupon Create Update failed');
    }
    console.log('dealerDataEdit', itemData);
  };

  const editLeadCoupon = async (data, itemData) => {
    debugger;
    itemData.couponCodeId = data;
    console.log('itemData,data', itemData, data);
    try {
      const apiUrl = `${LeadUpdateApi}${itemData?.leadsId}`;
      const response = await axios.put(apiUrl, itemData, {
        auth: {
          username: userId,
          password: password,
        },
      });
      console.log('response.data', response);
      if (response?.status === 200)
        toast.success('Lead Coupon created Successfully');
      else toast.error('Lead Coupon create failed');
    } catch (error) {
      console.log('error', error);
      toast.error('Lead Coupon create failed');
    }
    console.log('dealerDataEdit', itemData);
    getLeads(0, 10);
  };

  return (
    <Context.Provider
      value={{
        ...props,
        getLeads: getLeads,
        getFilterLeads: getFilterLeads,
        leads,
        data,
        dropDownList: dropDownList,
        cityList: cityList,
        createLead: createLead,
        editLead: editLead,
        createCouponCode: createCouponCode,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default LeadProvider;
