export const leadTableHeaders = [
  'Id',
  'Date',
  'Lead Details',
  'Email Address',
  'City',
  'Service Type',
  'Reminder',
  'Coupon Code',
  'Status',
  'Log',
  // 'Action',
];

export const leadsArray = [
  {
    leadsId: 35,
    tenantId: 6,
    integratorId: null,
    preferedPkgInstanceId: null,
    name: 'Unknown',
    mobileNumber: '9900282581',
    city: null,
    contactStatus: 'NA',
    isOTPActive: true,
    email: 'test',
    comments: null,
    employeeName: null,
    creationDate: '2024-08-27T17:20:41.000+00:00',
    lastModified: '2024-08-27T17:20:41.000+00:00',
    otp: '712284',
  },
  {
    leadsId: 36,
    tenantId: 6,
    integratorId: null,
    preferedPkgInstanceId: null,
    name: 'Unknown',
    mobileNumber: '9632444011',
    city: null,
    contactStatus: 'NA',
    isOTPActive: true,
    comments: null,
    employeeName: null,
    creationDate: '2024-08-28T14:32:52.000+00:00',
    lastModified: '2024-08-28T14:32:52.000+00:00',
    otp: '191162',
  },
  {
    leadsId: 37,
    tenantId: 6,
    integratorId: null,
    preferedPkgInstanceId: null,
    name: 'Unknown',
    mobileNumber: '919611188788',
    city: null,
    contactStatus: 'NA',
    isOTPActive: true,
    comments: null,
    employeeName: null,
    creationDate: '2024-08-28T14:36:26.000+00:00',
    lastModified: '2024-09-07T13:13:14.000+00:00',
    otp: '382173',
  },
  {
    leadsId: 38,
    tenantId: 6,
    integratorId: null,
    preferedPkgInstanceId: null,
    name: 'Unknown',
    mobileNumber: '918826977608',
    city: null,
    contactStatus: 'NA',
    isOTPActive: true,
    comments: null,
    employeeName: null,
    creationDate: '2024-08-28T15:25:53.000+00:00',
    lastModified: '2024-08-28T15:25:53.000+00:00',
    otp: '870061',
  },
  {
    leadsId: 39,
    tenantId: 6,
    integratorId: null,
    preferedPkgInstanceId: null,
    name: 'Unknown',
    mobileNumber: '919900282581',
    city: null,
    contactStatus: 'NA',
    isOTPActive: true,
    comments: null,
    employeeName: null,
    creationDate: '2024-08-28T17:03:31.000+00:00',
    lastModified: '2024-09-04T06:38:04.000+00:00',
    otp: '927497',
  },
  {
    leadsId: 40,
    tenantId: 6,
    integratorId: null,
    preferedPkgInstanceId: null,
    name: 'Unknown',
    mobileNumber: '919243025736',
    city: null,
    contactStatus: 'NA',
    isOTPActive: true,
    comments: null,
    employeeName: null,
    creationDate: '2024-08-29T02:09:22.000+00:00',
    lastModified: '2024-09-07T13:25:02.000+00:00',
    otp: '353903',
  },
  {
    leadsId: 41,
    tenantId: 6,
    integratorId: null,
    preferedPkgInstanceId: null,
    name: 'Unknown',
    mobileNumber: '917892096158',
    city: null,
    contactStatus: 'NA',
    isOTPActive: true,
    comments: null,
    employeeName: null,
    creationDate: '2024-08-29T04:55:41.000+00:00',
    lastModified: '2024-08-29T04:57:49.000+00:00',
    otp: '021046',
  },
  {
    leadsId: 42,
    tenantId: 6,
    integratorId: null,
    preferedPkgInstanceId: null,
    name: 'Unknown',
    mobileNumber: '919885532499',
    city: null,
    contactStatus: 'NA',
    isOTPActive: true,
    comments: null,
    employeeName: null,
    creationDate: '2024-08-29T09:25:11.000+00:00',
    lastModified: '2024-08-29T09:25:11.000+00:00',
    otp: '350880',
  },
  {
    leadsId: 43,
    tenantId: 6,
    integratorId: null,
    preferedPkgInstanceId: null,
    name: 'Unknown',
    mobileNumber: '919335959766',
    city: null,
    contactStatus: 'NA',
    isOTPActive: true,
    comments: null,
    employeeName: null,
    creationDate: '2024-08-29T09:31:53.000+00:00',
    lastModified: '2024-08-29T09:32:30.000+00:00',
    otp: '730654',
  },
  {
    leadsId: 44,
    tenantId: 6,
    integratorId: null,
    preferedPkgInstanceId: null,
    name: 'Unknown',
    mobileNumber: '919008922526',
    city: null,
    contactStatus: 'NA',
    isOTPActive: true,
    comments: null,
    employeeName: null,
    creationDate: '2024-08-29T12:00:42.000+00:00',
    lastModified: '2024-08-29T12:00:42.000+00:00',
    otp: '672145',
  },
];
