export const leadname = 'Lead Name';
export const emailAddress = 'Email Address';
export const mobileNumber = 'Mobile Number';
export const city = 'City';
export const notes = 'Notes';
export const addReminder = 'Add Reminder';
export const addLead = 'Add Lead';
export const clearFilter = 'Clear Filter';
export const editLeadConst = 'Edit Lead';
export const createCoupon = 'Create Coupon';
export const selectDate = 'Select Date';
export const selectTime = 'Select Time';
export const couponName = 'Coupon Name';
export const endDate = 'End Date';
export const discountAmount = 'Discount Amount';
export const noOfTimesUsable = 'No. Of times usable';

export const serviceType = 'Service Type';
// service type dropdown items
export const warranty = 'Warranty';
export const inspection = 'Inspection';
export const carRepair = 'Car Repair';
export const serviceTypeOptions = [
  { value: 'warranty', label: warranty },
  { value: 'inspection', label: inspection },
  { value: 'carRepair', label: carRepair },
];

export const cityListOptions = [
  { value: 'Delhi', label: 'Delhi' },
  { value: 'Mumbai', label: 'Mumbai' },
  { value: 'Bangalore', label: 'Bangalore' },
  { value: 'Hyderabad', label: 'Hyderabad' },
  { value: 'Chennai', label: 'Chennai' },
  { value: 'Kolkata', label: 'Kolkata' },
  { value: 'Pune', label: 'Pune' },
  { value: 'Ahmedabad', label: 'Ahmedabad' },
  { value: 'Jaipur', label: 'Jaipur' },
  { value: 'Surat', label: 'Surat' },
  { value: 'Lucknow', label: 'Lucknow' },
  { value: 'Kanpur', label: 'Kanpur' },
  { value: 'Nagpur', label: 'Nagpur' },
  { value: 'Indore', label: 'Indore' },
  { value: 'Vadodara', label: 'Vadodara' },
  { value: 'Bhopal', label: 'Bhopal' },
  { value: 'Coimbatore', label: 'Coimbatore' },
  { value: 'Patna', label: 'Patna' },
  { value: 'Agra', label: 'Agra' },
  { value: 'Visakhapatnam', label: 'Visakhapatnam' },
  { value: 'Chandigarh', label: 'Chandigarh' },
];

export const selectReason = 'Select Reason';
export const reasonOptions = [
  { value: 'notInterested', label: 'Not Interested' },
  { value: 'rnr', label: 'RNR' },
  { value: 'wrongNumber', label: 'Wrong Number' },
  { value: 'ccGenerated', label: 'CC Generated' },
  { value: 'paid', label: 'Paid' },
  { value: 'deadLead', label: ' Dead Lead' },
];

export const timeOptions = [
  { value: '00', label: '00' },
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
];
