import React from 'react';
import { TenantTable } from './tenantTable';

export const TenantComponent = () => {
  return (
    <div>
      <TenantTable />
    </div>
  );
};
