import React, { Fragment, useContext, useEffect, useState } from 'react';
import { leadsArray, leadTableHeaders } from '../../Constant/tableConstants';
import { AddNew } from '../../Constant';
import { Btn, H5 } from '../../AbstractElements';
import {
  Col,
  Card,
  CardHeader,
  Table,
  Media,
  Button,
  Modal,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Row,
  ModalBody,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import { PlusSquare } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { dateFormatFunc } from '../../_helper/utils/dateFormatFunc.jsx';
import {
  addLead,
  editLeadConst,
  addReminder,
  city,
  cityListOptions,
  couponName,
  createCoupon,
  discountAmount,
  emailAddress,
  endDate,
  leadname,
  mobileNumber,
  noOfTimesUsable,
  notes,
  reasonOptions,
  selectDate,
  selectReason,
  selectTime,
  serviceType,
  serviceTypeOptions,
  timeOptions,
  clearFilter,
} from '../../Constant/crmLabelConstants.jsx';
import LeadAppContext from '../../_helper/Leads/index.js';
import ModalFormComponent from '../modals/leadModal/modal.jsx';
import LeadForm from './forms/createLeadForm.jsx';
import { SearchIconss } from '../../Data/svgIcons';
import Searchbar from '../ReusableComponents/SearchBarComponent.jsx';
// import Searchbar from '../../Layout/Header/SearchBar.jsx';

const style2 = {
  width: 60,
  fontSize: 13,
  padding: 3,
};

const LeadsTable = () => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const {
    getLeads,
    getFilterLeads,
    leads,
    dropDownList,
    cityList,
    createLead,
    editLead,
    createCouponCode,
  } = useContext(LeadAppContext);
  const [leadList, setLeadList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editItem, setEditItem] = useState();
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);
  const [isCouponModalopen, setIsCouponModalopen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [serviceTypesSearch, setServiceTypesSearch] = useState('');
  const [contactStatusSearch, setContactStatusSearch] = useState('');
  const [leadStatusSearch, setLeadStatusSearch] = useState('');
  const paginationArray = Array.from(
    { length: leads?.totalPages },
    (_, index) => index + 1
  );

  const handleSearch = (value) => {
    setSearchValue(value);
    getFilterLeads(value, 0, 10);
  };
  const handleSearchClick = () => {
    const leadsFilterArray = leads?.content?.filter(
      (filItem) =>
        filItem?.serviceTypes?.toLowerCase() ==
          serviceTypesSearch.toLowerCase() ||
        filItem?.contactStatus?.toLowerCase() ==
          contactStatusSearch?.toLowerCase()
    );
    setLeadList(leadsFilterArray);
  };
  const toggle = () => {
    reset();
    setModal(!modal);
    setIsEdit(false);
  };
  const toggleReminder = () => {
    reset();
    setIsReminderModalOpen(!isReminderModalOpen);
    setIsEdit(false);
  };
  const toggleCoupon = () => {
    reset();
    setIsCouponModalopen(!isCouponModalopen);
    setIsEdit(false);
  };
  const selectedserviceTypes = watch('serviceTypes');

  const handleClearFilter = () => {
    if (searchValue) getLeads(0, 20);
    else setLeadList(leads?.content);
    setServiceTypesSearch('');
    setContactStatusSearch('');
    setLeadStatusSearch('');
    setSearchValue('');
  };

  const handleEdit = (item) => {
    setEditItem(item);
    setValue('leadsId', item?.leadsId);
    setValue('name', item?.name);
    setValue('mobileNumber', item?.mobileNumber);
    setValue('serviceTypes', item?.serviceTypes);
    setValue('city', item?.city);
    setValue('comments', item?.comments);
    setValue('contactStatus', item?.contactStatus);
    setValue('creationDate', item?.creationDate);
    setValue('employeeName', item?.employeeName);
    setIsEdit(true);
    setModal(true);
  };
  const handleReminder = (item) => {
    reset();
    setIsReminderModalOpen(!isReminderModalOpen);
  };
  const handleCoupon = (item) => {
    reset();
    console.log('itemitem item', item);
    setEditItem(item);
    setIsCouponModalopen(!isCouponModalopen);
  };
  const AddReminder = (data) => {
    console.log('data Reminder', data, watch('reminderDate'));
  };

  const AddLead = (data) => {
    if (data !== undefined) {
      createLead(data);
      setModal(false);
    } else {
      errors.showMessages();
    }
    reset();
  };
  const EditLead = (data) => {
    debugger;
    setEditItem((prevState) => ({
      ...prevState, // keep the existing properties
      ...data, // overwrite with new values
    }));
    if (data !== undefined) {
      editLead(data, editItem);
      setModal(false);
      setIsEdit(false);
    } else {
      errors.showMessages();
    }
    reset();
  };

  const AddCoupon = (data) => {
    console.log('editItem', editItem);
    if (data !== undefined) {
      createCouponCode(data, editItem);
      setIsCouponModalopen(false);
    } else {
      errors.showMessages();
    }
    reset();
  };

  const handlePagination = (pageNo) => {
    if (pageNo >= 0 && pageNo < leads?.totalPages) {
      setCurrentPage(pageNo);
      getLeads(pageNo, 20);
    }
  };

  useEffect(() => {
    setLeadList(leads?.content);
  }, [leads]);

  return (
    <Fragment>
      <Col sm="12" className="pt-5">
        <Card>
          <CardHeader>
            <Media>
              <Media body className="text-end">
                <span className="pull-right">
                  <Button
                    className="btn-block "
                    color="primary"
                    type="submit"
                    onClick={toggle}
                  >
                    <span className="d-flex justify-content-between align-items-center">
                      <PlusSquare size={15} className="m-1" /> {AddNew}
                    </span>
                  </Button>
                </span>
              </Media>
            </Media>
            <Row>
              <Col
                style={{
                  display: 'flex',
                  padding: '10px',
                  paddingBottom: '10px',
                  alignItems: 'center',
                }}
                sm="12"
              >
                <Col sm="3">
                  <Searchbar
                    placeHolder="Customer Name / Mobile Number"
                    searchValue={searchValue}
                    value={searchValue}
                    onSearch={handleSearch}
                    onSearchClick={handleSearchClick}
                  />
                </Col>
                <Col
                  style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                  sm="9"
                >
                  <Col sm="3">
                    <Input
                      type="select"
                      name="serviceTypesSearch"
                      placeholder="Select Service"
                      className="form-control digits"
                      value={serviceTypesSearch}
                      onChange={(e) => {
                        setServiceTypesSearch(e.target.value);
                      }}
                    >
                      <option value="" disabled selected>
                        -- Select Service --
                      </option>
                      {dropDownList?.ServiceTypes?.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </Input>
                  </Col>
                  <Col sm="3">
                    <Input
                      type="select"
                      name="contactStatusSearch"
                      value={contactStatusSearch}
                      placeholder="Select Status"
                      className="form-control digits"
                      onChange={(e) => setContactStatusSearch(e.target.value)}
                    >
                      <option value="" disabled selected>
                        -- Select Contact Status --
                      </option>
                      {dropDownList?.ContactStatus?.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </Input>
                  </Col>
                  <Col sm="3">
                    <Input
                      type="select"
                      name="leadStatusSearch"
                      value={leadStatusSearch}
                      placeholder="Select Service"
                      className="form-control digits"
                      onChange={(e) => setLeadStatusSearch(e.target.value)}
                    >
                      <option value="" disabled selected>
                        -- Select Lead Status--
                      </option>
                      {dropDownList?.LeadStatus?.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </Input>
                  </Col>
                  <Col sm="3">
                    <span style={{ display: 'flex', gap: '10px' }}>
                      <Button
                        className="btn-block "
                        color="primary"
                        onClick={handleSearchClick}
                      >
                        submit
                      </Button>

                      <Button
                        className="btn-block "
                        color="primary"
                        type="submit"
                        onClick={() => handleClearFilter()}
                      >
                        <span className="d-flex justify-content-between align-items-center">
                          <PlusSquare size={15} className="m-1" /> {clearFilter}
                        </span>
                      </Button>
                    </span>
                  </Col>
                </Col>
              </Col>
            </Row>
          </CardHeader>
          <div className="table-responsive">
            <Table>
              <thead>
                <tr>
                  {leadTableHeaders?.map((head) => (
                    <th scope="col">{head}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {leadList?.map((item) => (
                  <tr key={item.id}>
                    <th scope="row">{item?.leadsId}</th>
                    <td>{dateFormatFunc(item?.creationDate)}</td>
                    <td>
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <div>
                          <p style={{ marginBottom: '0px' }}>
                            {item?.mobileNumber}
                          </p>
                          <p style={{ marginBottom: '0px' }}> {item?.name}</p>
                        </div>
                        <i
                          className="fa fa-pencil"
                          onClick={() => handleEdit(item)}
                        ></i>
                      </div>
                    </td>
                    <td>{item?.email}</td>
                    <td>
                      {
                        cityList?.find((obj) => obj.cityId === item?.city)
                          ?.cityName
                      }
                    </td>
                    <td>{item?.serviceTypes ? item?.serviceTypes : '--'}</td>
                    <td>
                      <Button onClick={handleReminder}>Reminder</Button>
                    </td>
                    <td>
                      {item?.couponCodeId ? (
                        <>
                          <p style={{ marginBottom: '0px' }}>
                            {item?.couponCodeId?.couponcode}{' '}
                          </p>
                          <p style={{ marginBottom: '0px' }}>
                            {item?.couponCodeId?.discountAmount}
                          </p>
                        </>
                      ) : (
                        <Button onClick={() => handleCoupon(item)}>
                          Create Copun
                        </Button>
                      )}
                    </td>

                    <td>{item?.contactStatus}</td>
                    <td>Log</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '15px',
            }}
          >
            <nav className="m-b-30" aria-label="Page navigation example">
              <Pagination
                className="pagination pagination-primary"
                aria-label="Page navigation example"
              >
                <PaginationItem>
                  <PaginationLink
                    onClick={() => handlePagination(currentPage - 1)}
                  >
                    {'Previous'}
                  </PaginationLink>
                </PaginationItem>
                {paginationArray?.map((_item, index) => (
                  <PaginationItem key={index + 2}>
                    <PaginationLink onClick={() => handlePagination(index)}>
                      {index}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem>
                  <PaginationLink
                    onClick={() => handlePagination(currentPage + 1)}
                  >
                    {'Next'}
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </div>
        </Card>
      </Col>

      {/* modal Add and edit */}
      {modal && (
        <Modal isOpen={modal} toggle={toggle} size="lg">
          <ModalHeader>
            {isEdit ? editLeadConst : addLead}
            <Btn
              attrBtn={{
                color: 'transprant',
                className: 'btn-close',
                onClick: toggle,
                type: 'button',
              }}
            ></Btn>
          </ModalHeader>
          <ModalBody>
            <Form
              className="form-bookmark needs-validation"
              // onSubmit={handleSubmit(AddContact)}
              onSubmit={isEdit ? handleSubmit(EditLead) : handleSubmit(AddLead)}
            >
              <div className="form-row">
                <FormGroup className="col-md-12">
                  <Row>
                    <Col sm="6">
                      <Label>{leadname}</Label>
                      <input
                        className="form-control"
                        name="name"
                        type="text"
                        {...register('name', { required: true })}
                      />
                      <span style={{ color: 'red' }}>
                        {errors.name && 'Lead name is required'}
                      </span>
                    </Col>
                    <Col sm="6">
                      <Label>{emailAddress}</Label>
                      <input
                        className="form-control"
                        name="email"
                        type="text"
                        disabled={!isEdit}
                        {...register('email', { required: false })}
                      />
                      <span style={{ color: 'red' }}>
                        {errors.email && 'Please enter email.'}
                      </span>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="col-md-12">
                  <Row>
                    <Col sm="6">
                      <Label>{mobileNumber}</Label>
                      <input
                        className="form-control"
                        name="mobileNumber"
                        type="tel"
                        {...register('mobileNumber', {
                          required: 'Mobile number is required',
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message:
                              'Please enter a valid 10-digit mobile number',
                          },
                          minLength: {
                            value: 10,
                            message: 'Mobile number must be exactly 10 digits',
                          },
                          maxLength: {
                            value: 10,
                            message: 'Mobile number must be exactly 10 digits',
                          },
                        })}
                      />
                      <span style={{ color: 'red' }}>
                        {errors.mobileNumber && 'Please enter valid Mobile'}
                      </span>
                    </Col>
                    <Col sm="6">
                      <Label>{serviceType}</Label>
                      <Input
                        type="select"
                        name="serviceTypes"
                        value={selectedserviceTypes}
                        placeholder="Select Service"
                        className="form-control digits"
                        {...register('serviceTypes', {
                          required: true,
                        })}
                        onChange={(e) =>
                          setValue('serviceTypes', e.target.value)
                        }
                      >
                        <option value="" disabled selected>
                          -- Select Service --
                        </option>
                        {dropDownList?.ServiceTypes?.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </Input>
                      <span style={{ color: 'red' }}>
                        {errors.serviceTypes &&
                          'Please enter valid service type'}
                      </span>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="col-md-12">
                  <Row>
                    <Col sm="6">
                      <Label>{city}</Label>
                      <Input
                        type="select"
                        name="city"
                        placeholder="Select City"
                        className="form-control digits"
                        {...register('city', { required: true })}
                        onChange={(e) => setValue('city', e.target.value)}
                      >
                        <option value="" disabled selected>
                          -- Select --
                        </option>
                        {cityList?.map((item) => (
                          <option value={item?.cityId}>{item?.cityName}</option>
                        ))}
                      </Input>
                      <span style={{ color: 'red' }}>
                        {errors.city && 'Please enter valid city'}
                      </span>
                    </Col>
                    <Col sm="6"></Col>
                  </Row>
                </FormGroup>
                <FormGroup className="col-md-12">
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label>{notes}</Label>
                        <input
                          type="textarea"
                          className="form-control"
                          name="comments"
                          rows="3"
                          style={{ height: '80px' }}
                          {...register('comments', { required: true })}
                        />
                        <span style={{ color: 'red' }}>
                          {errors.comments && 'Some comments are required'}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </FormGroup>
              </div>
              <Btn
                attrBtn={{ color: 'secondary', className: 'me-1' }}
                type="submit"
              >
                {isEdit ? 'Update' : 'Save'}
              </Btn>
              &nbsp;
              <Btn attrBtn={{ color: 'primary', onClick: toggle }}>
                {'Cancel'}
              </Btn>
            </Form>
          </ModalBody>
        </Modal>
      )}

      {/* Modal Reminder */}
      {isReminderModalOpen && (
        <Modal isOpen={isReminderModalOpen} toggle={toggleReminder} size="lg">
          <ModalHeader>
            {addLead}
            <Btn
              attrBtn={{
                color: 'transprant',
                className: 'btn-close',
                onClick: toggleReminder,
                type: 'button',
              }}
            ></Btn>
          </ModalHeader>
          <ModalBody>
            <Form
              className="form-bookmark needs-validation"
              onSubmit={handleSubmit(AddReminder)}
            >
              <div className="form-row">
                <FormGroup className="col-md-12">
                  <Row>
                    <Col sm="4">
                      <Label>{selectDate}</Label>
                      <Controller
                        name="reminderDate"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            className="datepicker-here form-control"
                            selected={field.value}
                            onChange={(date) => {
                              // Log the selected date to the console
                              field.onChange(date);
                            }}
                            required
                            dateFormat="yyyy/MM/dd"
                            placeholderText="Select a date"
                          />
                        )}
                      />
                      {errors.reminderDate && (
                        <span className="text-danger">
                          This field is required
                        </span>
                      )}
                    </Col>
                    <Col sm="4">
                      <Label>{selectTime}</Label>
                      <Input
                        type="select"
                        name="time"
                        placeholder="Select time"
                        className="form-control digits"
                        {...register('time', { required: true })}
                        onChange={(e) => setValue('time', e.target.value)}
                      >
                        <option value="" disabled selected>
                          -- Select --
                        </option>
                        {timeOptions?.map((item) => (
                          <option value={item?.value}>{item?.label}</option>
                        ))}
                      </Input>
                      {errors.time && (
                        <span className="text-danger">Time is required</span>
                      )}
                    </Col>
                    <Col sm="4">
                      <Label>{selectReason}</Label>
                      <Input
                        type="select"
                        name="reason"
                        placeholder="Select Reminder Reason"
                        className="form-control digits"
                        {...register('reason', { required: true })}
                        onChange={(e) => setValue('reason', e.target.value)}
                      >
                        <option value="" disabled selected>
                          -- Select Reminder Reason --
                        </option>
                        {reasonOptions?.map((item) => (
                          <option value={item?.value}>{item?.label}</option>
                        ))}
                      </Input>
                      {errors.reason && (
                        <span className="text-danger">Time is required</span>
                      )}
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="col-md-12">
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label>{notes}</Label>
                        <input
                          type="textarea"
                          className="form-control"
                          name="notes"
                          rows="3"
                          style={{ height: '80px' }}
                          {...register('notes', { required: true })}
                        />
                        <span style={{ color: 'red' }}>
                          {errors.notes && 'Some notes is required'}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </FormGroup>
              </div>
              <Btn
                attrBtn={{
                  color: 'secondary',
                  className: 'me-1',
                  // onClick: AddReminder,
                }}
                type="submit"
              >
                {'Save'}
              </Btn>
              &nbsp;
              <Btn attrBtn={{ color: 'primary', onClick: toggleReminder }}>
                {'Cancel'}
              </Btn>
            </Form>
          </ModalBody>
        </Modal>
      )}

      {/* modal Generate Copun */}
      {isCouponModalopen && (
        <Modal isOpen={isCouponModalopen} toggle={toggleCoupon} size="lg">
          <ModalHeader>
            {createCoupon}
            <Btn
              attrBtn={{
                color: 'transprant',
                className: 'btn-close',
                onClick: toggleCoupon,
                type: 'button',
              }}
            ></Btn>
          </ModalHeader>
          <ModalBody>
            <Form
              className="form-bookmark needs-validation"
              // onSubmit={handleSubmit(AddContact)}
              onSubmit={
                handleSubmit(AddCoupon)
                // isEdit ? handleSubmit(EditDealer) : handleSubmit(AddDealer)
              }
            >
              <div className="form-row">
                <FormGroup className="col-md-12">
                  <Row>
                    <Col sm="6">
                      <Label>{couponName}</Label>
                      <input
                        className="form-control"
                        name="couponName"
                        type="text"
                        {...register('couponName', { required: true })}
                      />
                      <span style={{ color: 'red' }}>
                        {errors.couponName && 'Coupon name is required'}
                      </span>
                    </Col>
                    <Col sm="6">
                      <Label>{endDate}</Label>
                      <Controller
                        name="endDate"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            className="datepicker-here form-control"
                            selected={field.value}
                            onChange={(date) => {
                              // Log the selected date to the console
                              field.onChange(date);
                            }}
                            required
                            dateFormat="yyyy/MM/dd"
                            placeholderText="Select a date"
                          />
                        )}
                      />
                      <span style={{ color: 'red' }}>
                        {errors.endDate && 'Please enter end Date.'}
                      </span>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="col-md-12">
                  <Row>
                    <Col sm="6">
                      <Label>{discountAmount}</Label>
                      <input
                        className="form-control"
                        name="discountAmount"
                        type="text"
                        {...register('discountAmount', { required: true })}
                      />
                      <span style={{ color: 'red' }}>
                        {errors.discountAmount && 'Discount Amount is required'}
                      </span>
                    </Col>
                    <Col sm="6">
                      <Label>{noOfTimesUsable}</Label>
                      <input
                        className="form-control"
                        name="noOfTimesUsable"
                        type="text"
                        {...register('noOfTimesUsable', { required: true })}
                      />
                      <span style={{ color: 'red' }}>
                        {errors.noOfTimesUsable &&
                          'No Of Times Usable is required'}
                      </span>
                    </Col>
                  </Row>
                </FormGroup>
              </div>
              <Btn
                attrBtn={{ color: 'secondary', className: 'me-1' }}
                type="submit"
              >
                {isEdit ? 'Update' : 'Save'}
              </Btn>
              &nbsp;
              <Btn attrBtn={{ color: 'primary', onClick: toggleCoupon }}>
                {'Cancel'}
              </Btn>
            </Form>
          </ModalBody>
        </Modal>
      )}
    </Fragment>
  );
};

export default LeadsTable;
