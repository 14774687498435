import { AddNew, AddDealer, Name } from '../../Constant';
import { Btn, H5 } from '../../AbstractElements';
import defaultuser from '../../assets/images/user/user.png';
import {
  Col,
  Card,
  CardHeader,
  Table,
  Media,
  Button,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  FormGroup,
  Form,
} from 'reactstrap';
import React, { Fragment, useContext, useState } from 'react';
import { PlusSquare } from 'react-feather';
import { useForm } from 'react-hook-form';
import CustomerAppContext from '../../_helper/Customer';
import { toast } from 'react-toastify';

const style2 = {
  width: 60,
  fontSize: 13,
  padding: 3,
};

const tableHeaders = [
  'Id',
  'Customer Name',
  'Email',
  'Gender',
  'IsActive',
  'Tenant Id',
  'Last Modified Date',
  'Phone Number',
  'Action',
];

const CustomerTable = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();
  const [modal, setModal] = useState(false);
  const toggle = () => {
    reset();
    setModal(!modal);
  };
  const [isEdit, setIsEdit] = useState(false);
  const { createCustomer, editCustomer, deletedCustomer, customersList } =
    useContext(CustomerAppContext);

  const handleEdit = (item) => {
    console.log('item?.id', item);
    setValue('tenantId', item?.tenant_id);
    setValue('dealerId', item?.dealerId);
    setValue('isActive', item?.isActive);
    setValue('dealerName', item?.dealerName);
    setValue('location', item?.location);
    setValue('email', item?.email);
    setValue('phoneNumber', item?.phoneNumber);
    setValue('taxId', item?.taxId);
    setIsEdit(true);
    setModal(true);
  };

  const handleDelete = (item) => {
    if (item) {
      deletedCustomer(item?.dealerId);
      toast.success('Dealer Deleted Successfully');
    }
  };

  console.log('data', customersList);

  const AddDealer = (data) => {
    if (data !== undefined) {
      createCustomer(data, defaultuser);
      toast.success('Dealer Created Successfully');
      setModal(false);
    } else {
      errors.showMessages();
    }
    reset();
  };
  const EditDealer = (data) => {
    debugger;
    if (data !== undefined) {
      editCustomer(data, defaultuser);
      toast.success('Dealer Updated Successfully');
      setModal(false);
    } else {
      errors.showMessages();
    }
    reset();
  };

  return (
    <Fragment>
      <Col sm="12">
        <Card>
          <CardHeader>
            <Media>
              <H5>Customer</H5>
              <Media body className="text-end">
                <span className="pull-right">
                  {/* <Btn attrBtn={{ color: "primary" }}>
                    <span>
                      <i className="fa fa-plus text-white"></i>
                    </span>
                    {AddNew}
                  </Btn> */}
                  <Button
                    className="btn-block "
                    color="primary"
                    type="submit"
                    // onClick={toggle}
                  >
                    <span className="d-flex justify-content-between align-items-center">
                      <PlusSquare size={15} className="m-1" /> {AddNew}
                    </span>
                  </Button>
                </span>
              </Media>
            </Media>
          </CardHeader>
          <div className="table-responsive">
            <Table>
              <thead>
                <tr>
                  {tableHeaders?.map((head) => (
                    <th scope="col">{head}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {customersList?.map((item) => (
                  <tr key={item.id}>
                    <th scope="row">{item?.custId}</th>
                    <td>{item?.first_name}</td>
                    <td>{item?.email_id}</td>
                    <td>{item?.gender}</td>
                    <td>{item?.isActive ? 'True' : 'False'}</td>
                    <td>{item?.tenant_id}</td>
                    <td>{item?.lastModified}</td>
                    <td>{item?.mobile_number}</td>
                    <td>
                      <div>
                        <span>
                          <Button
                            style={style2}
                            className="btn btn-xs"
                            color="danger"
                            type="button"
                            // onClick={(e) => handleDelete(item)}
                          >
                            Delete
                          </Button>
                        </span>
                        <span>
                          <Button
                            style={style2}
                            className="btn btn-xs ms-2"
                            color="primary"
                            type="button"
                            // onClick={(e) => handleEdit(item)}
                          >
                            Edit{' '}
                          </Button>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader>
          {AddDealer}
          <Btn
            attrBtn={{
              color: 'transprant',
              className: 'btn-close',
              onClick: toggle,
              type: 'button',
            }}
          ></Btn>
        </ModalHeader>
        <ModalBody>
          <Form
            className="form-bookmark needs-validation"
            onSubmit={
              isEdit ? handleSubmit(EditDealer) : handleSubmit(AddDealer)
            }
          >
            <div className="form-row">
              <FormGroup className="col-md-12">
                <Row>
                  <Col sm="6">
                    <Label>{Name}</Label>
                    <input
                      className="form-control"
                      name="dealerName"
                      type="text"
                      {...register('dealerName', { required: true })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.dealerName && 'Dealer name is required'}
                    </span>
                  </Col>
                  {/* <Col sm="6">
                    <Label>{DealerType}</Label>
                    <input
                      className="form-control"
                      name="dealerType"
                      type="text"
                      {...register("dealerType", { required: true })}
                    />
                    <span style={{ color: "red" }}>
                      {errors.dealerType && "Last name is required"}
                    </span>
                  </Col> */}
                  <Col sm="6">
                    <Label>{'Location'}</Label>
                    <input
                      className="form-control"
                      name="location"
                      type="text"
                      {...register('location', { required: true })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.location && 'Please enter Location'}
                    </span>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup className="col-md-12">
                <Row>
                  <Col sm="6">
                    <Label>{'Email Address'}</Label>
                    <input
                      className="form-control"
                      name="email"
                      type="text"
                      {...register('email', { required: true })}
                      {...register('email', {
                        required: true,
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: 'Please enter a valid Email address',
                        },
                      })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.email && 'Please Enter Valid Email Address.'}
                    </span>
                  </Col>
                  <Col sm="6">
                    <Label>{'Is Active'}</Label>
                    <Input
                      type="select"
                      className="form-control"
                      name="isActive"
                      {...register('isActive', {
                        required: false,
                      })}
                      onChange={(e) => setValue('isActive', e.target.value)}
                    >
                      <option value={1}>True</option>
                      <option value={0}>False</option>
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup className="col-md-12">
                <Row>
                  <Col sm="6">
                    <Label>{'Tax Id'}</Label>
                    <input
                      className="form-control"
                      name="taxId"
                      type="text"
                      {...register('taxId', { required: true })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.taxId && 'Please enter tax Id'}
                    </span>
                  </Col>
                  <Col sm="6">
                    <Label>{'Mobile'}</Label>
                    <input
                      className="form-control"
                      name="phoneNumber"
                      type="tel"
                      {...register('phoneNumber', {
                        required: 'Phone number is required',
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message:
                            'Please enter a valid 10-digit mobile number',
                        },
                        minLength: {
                          value: 10,
                          message: 'Mobile number must be exactly 10 digits',
                        },
                        maxLength: {
                          value: 10,
                          message: 'Mobile number must be exactly 10 digits',
                        },
                      })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.phoneNumber && 'Please enter valid Mobile'}
                    </span>
                  </Col>
                </Row>
              </FormGroup>
            </div>
            <Btn
              attrBtn={{ color: 'secondary', className: 'me-1' }}
              type="submit"
            >
              {isEdit ? 'Update' : 'Save'}
            </Btn>
            &nbsp;
            <Btn attrBtn={{ color: 'primary', onClick: toggle }}>
              {'Cancel'}
            </Btn>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default CustomerTable;
