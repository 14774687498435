import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Context from './index';
import { base_url_CRM } from '../../apiService';
import { login, password, userId } from '../authVar';
import { DealerApi } from '../../api';
import { toast } from 'react-toastify';

const DealerProvider = (props) => {
  const [dealers, setDealers] = useState([]);
  const [data, setData] = useState([]);

  const getDealers = async () => {
    try {
      const response = await axios.get(DealerApi, {
        auth: {
          username: userId,
          password: password,
        },
      });
      setDealers(response.data);
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    if (login) {
      getDealers();
    }
  }, [setDealers, setData]);

  const createDealer = async (data1, imgUrl) => {
    const apiUrl = `${base_url_CRM}/dealer/add?tenantId=6`;
    const dealerDataSave = {
      dealerName: data1.dealerName,
      dealerType: Number(data1.dealerType),
      email: data1.email,
      location: data1?.location,
      isActive: Number(data1.isActive),
      taxId: data1.taxId,
      phoneNumber: data1.phoneNumber,
    };
    try {
      const response = await axios.post(apiUrl, dealerDataSave, {
        auth: {
          username: userId,
          password: password,
        },
      });
      if (response?.status == 200) toast.success('Dealer Created Successfully');
      else toast.error('Createing new Dealer failed');
    } catch (error) {
      console.log('error', error);
      toast.error('Createing new Dealer failed');
    }

    getDealers();
  };

  const editDealer = async (data, imgUrl) => {
    const dealerDataEdit = {
      dealerId: data?.dealerId,
      dealerName: data.dealerName,
      email: data.email,
      location: data?.location,
      isActive: Number(data.isActive),
      taxId: data.taxId,
      phoneNumber: data?.phoneNumber,
    };
    try {
      const apiUrl = `${base_url_CRM}/dealer/update?tenantId=6`;
      const response = await axios.put(apiUrl, dealerDataEdit, {
        auth: {
          username: userId,
          password: password,
        },
      });
      if (response?.status == 200) toast.success('Dealer Updated Successfully');
      else toast.error('Dealer Update failed');
    } catch (error) {
      console.log('error', error);
      toast.error('Dealer Update failed');
    }
    getDealers();
  };

  const deletedDealer = async (id) => {
    debugger;
    const apiUrl = `${base_url_CRM}/dealer/delete?id=${id}`;
    try {
      const response = await axios.delete(apiUrl, {
        auth: {
          username: userId,
          password: password,
        },
      });
      if (response?.status == 200) toast.success('Dealer Deleted Successfully');
      else toast.error('Dealer Deleting failed');
    } catch (error) {
      console.log('error', error);
      toast.error('Dealer Deleting failed');
    }
    getDealers();
  };

  return (
    <Context.Provider
      value={{
        ...props,
        dealers,
        data,
        createDealer: createDealer,
        editDealer: editDealer,
        deletedDealer: deletedDealer,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default DealerProvider;
