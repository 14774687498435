import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Context from './index';
import { base_url_CRM } from '../../apiService';
import { login, password, tenantId, userId } from '../authVar';
import { AuthApi } from '../../api';
import { toast } from 'react-toastify';

const AuthProvider = (props) => {
  const [data, setData] = useState([]);

  const userlogin = async (data) => {
    try {
      const queryParams = new URLSearchParams(data).toString();
      const apiUrlWithParams = `${AuthApi}?${queryParams}`;
      const response = await axios.get(apiUrlWithParams, {
        auth: {
          username: data?.email_id,
          password: data?.pwd,
        },
      });
      if (response?.status === 200) {
        localStorage.setItem('userId', data?.email_id);
        localStorage.setItem('password', data?.pwd);
        localStorage.setItem('login', JSON.stringify(true));
        toast.success('Login Success...!');
      } else {
        toast.error('Login Failed...!');
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <Context.Provider
      value={{
        ...props,
        userlogin: userlogin,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default AuthProvider;
